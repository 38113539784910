import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import ReactPlayer from 'react-player'

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <Seo
        title="Web Design Trilith Atlanta - Hanna Brothers - Websuasion"
        ogDescription="Are you looking for a professional web developer serving Trilith Atlanta Studios? See how we enabled Hanna Brother to achieve their goals."
        image="/images/case-studies/web-design-pinewood-atlanta-studios.jpg"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Web Design Trilith Atlanta Studios</h1>
						<h3>A Case Study of Hanna Brothers</h3>
            <p>
							<span className="websuasion">websuasion</span> enabled Hanna Brothers to achieve their web development and web presence goals by providing website design, photography, and ongoing support.
            </p>
						<p>
							Are you looking for a professional web designer serving the Trilith Atlanta Studios businesses to help transform your business? Let's schedule an introductory call.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
        textRight={
          <div>
					<img src="/images/case-studies/web-design-pinewood-atlanta-studios.jpg" className="rounded-image" alt="Web Design Pinewood Trilith Atlanta Studios - Websuasion" />
          </div>
        }
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<p>
								<img src="/images/case-studies/hanna-brothers-logo.webp" alt="Web Design Pinewood Trilith Atlanta Studios - Websuasion" />
							</p>
			        <p>
								Hanna Brothers has been providing "on-location" catering services to the film & television industry for over 15 years. From the major studios to the independent features we have worked in extreme conditions, pleasing palettes all over the world.
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="violet">
	      <TextBlock
	        textPadded
	        textLeft={
	          <div>
							<h2>Client Testimonial</h2>
	            <Blockquote>Having just completed a collaborative redesign of our company website, I wanted to give a big shout out to J. Ryan and his team at websuasion. They were very helpful from start to finish, adding insight and suggestions along the way. We are very happy with our finished project and look forward to future endeavors with the Websuasion team.</Blockquote>
							<p>
								- Jim Hanna, Co-Founder & Owner
							</p>
	          </div>
	        }
	        textRight={
	          <div>
							<img src="/images/case-studies/hanna-brothers-jim-hanna.jpg" className="rounded-square" alt="Web Design Pinewood Trilith Atlanta Studios - Websuasion" width="300" />
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
      <TextBlock
        textPadded
        textLeft={
          <div>
						<h2>Client Needs</h2>
						<ul>
							<li>Website redesign</li>
			        <li>Updated content</li>
							<li>Photographs for advertising</li>
						</ul>
          </div>
        }
        textRight={
          <div>
						<h2>websuasion Solutions</h2>
						<ul>
							<li>Desktop, mobile, and tablet responsive website</li>
			        <li>Content organization and editing</li>
							<li>Event photography</li>
						</ul>
          </div>
        }
      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Marketing Website Design</h2>
							<p>
								In 2016, we were contracted to redesign the Hanna Brothers website to best showcase their current services and product offerings. The staff provided extensive content and an archive of photos from catering jobs for various film shoots, weddings, and corporate events. We provided content architecture and guidance on culling down the current content to more digestable marketing messages targeted to their client base. We created a custom responsive website layout designed to showcase their film catering, event catering, disaster relief services, and new Hanna Brothers To Go service for large businesses. The site incorporates interactive menus, yearly film credits with rotating carousels of film posters, embedded video, and parallax imagery throughout the site.
							</p>
							<p>
			          <ReactPlayer
			            url= '/files/case-studies/hannabrothers.mp4'
			            width='100%'
			            height='100%'
			            controls = {true}
			          />
							</p>
							<p>
									We later were tasked with providing the same design solution for the new HB Studio Cafe website.
							</p>
							<p>
								<img src="/images/case-studies/hbstudiocafe.webp" alt="Web Design Pinewood Trilith Atlanta Studios - Websuasion" />
							</p>
	          </div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Photography</h2>
							<p>
								We were asked to take photographs at the 2016 Fayette Gala event hosted at Pinewood Atlanta. Hanna Brothers provided catering for the event and wanted photos that could be used for advertising, posters, and flyers showcasing their new Pinewood Cafe, and social media campaigns promoting their event catering services.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>
								On a continual basis, we provide content maintenance and hosting of the existing site.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default IndexPage
